export const projects = [
  {
    name: "eserciziAaut",
    order: 1,
    link: "https://github.com/lorenzobotto/eserciziAaut"
  },
  {
    name: "esercizi-tln",
    order: 2,
    link: "https://github.com/lorenzobotto/esercizi-tln"
  },
  {
    name: "progettoMaadb",
    order: 3,
    link: "https://github.com/lorenzobotto/progettoMaadb"
  },
  {
    name: "progettoTaass",
    order: 4,
    link: "https://github.com/lorenzobotto/progettoTaass"
  },
  {
    name: "oc-implementative-kp",
    order: 5,
    link: "https://github.com/lorenzobotto/oc-implementative-kp"
  },
  {
    name: "portfolioWebsite",
    order: 6,
    link: "https://github.com/lorenzobotto/portfolioWebsite"
  }
];

export const skills = [
  "Python (Jupyter Notebooks, NumPy, Pandas, SciPy, Scikit-learn, ...)",
  "Deep Learning (PyTorch, TensorFlow, Transformers, RNN, ResNet, ViT, ...)",
  "Machine Learning (XGBoost, LightGBM, CatBoost, Random Forest, ...)",
  "NLP e Computer Vision (NLTK, spaCy, Transformers, Torchvision, Timm, OpenCV, Pillow, Albumentations)",
  "Data Science (Feature Engineering, Data Cleaning, Data Visualization - Matplotlib e Seaborn, Web Scraping - Selenium, Beautiful Soup)",
  "Vector Search (FAISS)",
  "DevOps (Docker, Kubernetes, GCP)",
  "Databases (SQL, NoSQL - MongoDB)",
  "Version Control (Git)",
  "Web Development (React, FastAPI)"
];

export const timelineElements = [
  {
    id: 6,
    title: "AI Developer",
    location: "Convey S.r.l., Torino",
    description:
    [
      "Sviluppo di una pipeline ML per l'associazione di prodotti lessicalmente differenti tra piattaforme diverse, integrando analisi di testi e immagini e coprendo tutte le fasi dall'analisi dei dati alla validazione dei modelli.", 
      "Sviluppo di una pipeline ML altamente scalabile per il riconoscimento di immagini violative di copyright.",
      "Sviluppo di una pipeline ML per la categorizzazione dell'abbigliamento nel settore moda.",
      "Sviluppo di un progetto per la generazione di immagini tramite AI per soggetti/caratteri identici.",
      "Sviluppo di una pipeline che integra Object Detection e LLM per l'estrazione automatica e generica di informazioni specifiche dalle pagine web."
    ],
    date: "Agosto 2023 - Presente"
  },
  {
    id: 5,
    title: "Corso di Laurea Magistrale in Informatica",
    location: "Università degli studi di Torino",
    description:
      ["Laurea Magistrale in Informatica, con voto finale di 110/110, nel campo di studi \"Intelligenza Artificiale\"."],
    date: "Settembre 2021 - Aprile 2024"
  },
  {
    id: 4,
    title: "Software Engineer",
    location: "Tesisquare S.p.A., Cherasco",
    description: [
      "Come tirocinio universitario per la laurea triennale, ho sviluppato un chatbot conversazionale utilizzando la Google Cloud Platform (GCP) e Dialogflow.\n \
      Sviluppo di un progetto per integrare diverse fonti di dati in Google Cloud Search per uso interno."
    ],
    date: "Maggio 2021 - Luglio 2021"
  },
  {
    id: 3,
    title: "Corso di Laurea Triennale in Informatica",
    location: "Università degli studi di Torino",
    description:
      ["Laurea triennale in Informatica, con voto finale di 104/110, nel campo di studi \"Reti e Sistemi Informatici\"."],
    date: "Settembre 2018 - Novembre 2021"
  },
  {
    id: 2,
    title: "Software Engineer",
    location: "Tesisquare S.p.A., Cherasco",
    description:
      [
        "Realizzazione di nuove implementazioni software a seconda delle richieste da parte dei clienti.",
        "Manutenzione e miglioramento dei servizi già esistenti."
      ],
    date: "Agosto 2018 - Novembre 2018"
  },
  {
    id: 1,
    title: "Diploma in Sistemi Informativi Aziendali",
    location: "ITCG E. Guala, Bra",
    description:
      ["Diploma in Sistemi Informativi Aziendali con voto finale di 86/100."],
    date: "Luglio 2018"
  }
];